import { DAYS_IN_YEAR } from "@/constants/common";
import { ProfileToEnergyConsumptionData } from "@/modules/play/context/playContext";
import {
  carEnergies,
  houseEnergies,
  houseLighting,
  IntermediateValues,
  PersoForm,
} from "@/modules/play/Personalization/models/form";

import {
  getCarAgeCoeff,
  getHeatingConsumption,
  getHeatingConsumptionInvoiceCoeff,
  getShowerBathCoeff,
  getWhiteProductsCoeff,
} from "./utils";

export const computeIntermediateValues = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
): IntermediateValues => {
  return {
    whiteProductsCoeff: getWhiteProductsCoeff(
      personalization,
      profileToEnergyConsumptionData,
    ),
    showerBathCoeff: getShowerBathCoeff(personalization),
    brownGoodsConsumption: 0,
    heatingConsumptionInvoiceCoeff: getHeatingConsumptionInvoiceCoeff(
      personalization,
      profileToEnergyConsumptionData,
    ),
  };
};

export const getFossilCarConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const {
    car,
    carEnergy,
    numberAdults,
    numberKids,
    carConsumption,
    carDistanceAlone,
    carDistanceHoushold,
    carDistanceCarsharing,
  } = personalization;

  if (!car) {
    return (
      ((carDistanceCarsharing *
        profileToEnergyConsumptionData.transportCoeffs
          .MEAN_FOSSIL_CAR_CONSUMPTION_IN_L_PER_KM) /
        DAYS_IN_YEAR) *
      profileToEnergyConsumptionData.GASOLINE_ENERGY_DENSITY_IN_KWH_PER_L
    );
  }

  if (
    ![
      carEnergies.DIESEL.value,
      carEnergies.GPL.value,
      carEnergies.HYBRIDE.value,
      carEnergies.ESSENCE.value,
    ].includes(carEnergy)
  ) {
    return 0;
  }

  return (
    (((carDistanceAlone / DAYS_IN_YEAR +
      carDistanceHoushold /
        (DAYS_IN_YEAR * (Math.max(numberAdults, 1) + numberKids))) *
      carConsumption) /
      100 +
      (carDistanceCarsharing *
        profileToEnergyConsumptionData.transportCoeffs
          .MEAN_FOSSIL_CAR_CONSUMPTION_IN_L_PER_KM) /
        DAYS_IN_YEAR) *
    profileToEnergyConsumptionData.GASOLINE_ENERGY_DENSITY_IN_KWH_PER_L
  );
};

export const getFossilHeatingConsumption = (
  personalization: PersoForm,
  intermediateValues: IntermediateValues,
) => {
  const { heatingEnergy } = personalization;
  if (
    ![houseEnergies.FIOUL.value, houseEnergies.GAZ.value].includes(
      heatingEnergy,
    )
  ) {
    return 0;
  }
  return getHeatingConsumption(
    personalization,
    intermediateValues.heatingConsumptionInvoiceCoeff,
  );
};

export const getPlaneConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { planeDistance } = personalization;
  return (
    (planeDistance / DAYS_IN_YEAR) *
    profileToEnergyConsumptionData.transportCoeffs.PLANE
  );
};

export const getAirConditionningConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { airConditionning, aCDaysNb, aCRoomNb } = personalization;

  if (!airConditionning) {
    return 0;
  }
  return (
    (aCRoomNb *
      aCDaysNb *
      profileToEnergyConsumptionData.AIR_CONDITIONING_POWER *
      12) /
    DAYS_IN_YEAR
  );
};

export const getBrownGoodsConsumption = (
  personalization: PersoForm,
  intermediateValues: IntermediateValues,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { numericEquipment: hasALotOfDigitalEquipment } = personalization;

  const baseBrownGoodsConsumption = hasALotOfDigitalEquipment
    ? profileToEnergyConsumptionData.numericEquipment.YES
    : profileToEnergyConsumptionData.numericEquipment.NO;
  return baseBrownGoodsConsumption + intermediateValues.brownGoodsConsumption;
};

export const getCleanCookConsumption = (
  intermediateValues: IntermediateValues,
) => {
  return (
    intermediateValues.whiteProductsCoeff + intermediateValues.showerBathCoeff
  );
};

export const getElectricCarConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const {
    carEnergy,
    carDistanceAlone,
    carDistanceHoushold,
    carDistanceCarsharing,
    numberAdults,
    numberKids,
  } = personalization;
  if (carEnergy !== carEnergies.ELECTRICITE.value) {
    return 0;
  }

  return (
    (carDistanceAlone / DAYS_IN_YEAR +
      carDistanceHoushold / (DAYS_IN_YEAR * (numberAdults + numberKids)) +
      carDistanceCarsharing / DAYS_IN_YEAR) *
    profileToEnergyConsumptionData.transportCoeffs.ELECTRIC_CAR
  );
};

export const getLightConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { lightingSystem } = personalization;
  if (lightingSystem === houseLighting.AMPOULES_LED.value) {
    return profileToEnergyConsumptionData.lightingConstants.LED;
  }
  return profileToEnergyConsumptionData.lightingConstants.OTHER;
};

export const getNoCarbonHeatingConsumption = (
  personalization: PersoForm,
  intermediateValues: IntermediateValues,
) => {
  const { heatingEnergy } = personalization;

  if (
    ![houseEnergies.BOIS.value, houseEnergies.ELECTRICITE.value].includes(
      heatingEnergy,
    )
  ) {
    return 0;
  }
  return getHeatingConsumption(
    personalization,
    intermediateValues.heatingConsumptionInvoiceCoeff,
  );
};

export const getTrainConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { trainDistance } = personalization;
  return (
    (trainDistance / DAYS_IN_YEAR) *
    profileToEnergyConsumptionData.transportCoeffs.TRAIN
  );
};

export const getFoodConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const {
    eatingVegan,
    eatingVegetables,
    eatingDairies,
    eatingEggs,
    eatingMeat,
    eatingTinDrink,
    eatingZeroWaste,
    eatingCatNumber,
    eatingDogNumber,
    eatingHorse,
  } = personalization;
  const result = [];
  if (eatingVegan) {
    result.push(profileToEnergyConsumptionData.consumptionFood.VEGAN);
  } else {
    if (eatingVegetables) {
      result.push(profileToEnergyConsumptionData.consumptionFood.VEGETABLES);
    }
    if (eatingDairies) {
      result.push(profileToEnergyConsumptionData.consumptionFood.DAIRIES);
    }
    if (eatingEggs) {
      result.push(profileToEnergyConsumptionData.consumptionFood.EGGS);
    }
    if (eatingMeat) {
      result.push(profileToEnergyConsumptionData.consumptionFood.MEAT);
    }
  }
  if (!eatingZeroWaste) {
    result.push(profileToEnergyConsumptionData.consumptionFood.ZERO_WASTE);
  }
  if (eatingHorse) {
    result.push(profileToEnergyConsumptionData.consumptionFood.HORSE);
  }

  result.push(
    eatingTinDrink * profileToEnergyConsumptionData.consumptionFood.TIN_DRINK,
  );
  result.push(
    eatingCatNumber * profileToEnergyConsumptionData.consumptionFood.CAT,
  );
  result.push(
    eatingDogNumber * profileToEnergyConsumptionData.consumptionFood.DOG,
  );
  result.push(profileToEnergyConsumptionData.consumptionFood.GLOBAL);

  return result.reduce((total, term) => total + term, 0);
};

export const getGreyCarConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { car, carEnergy, carAge } = personalization;

  if (!car) {
    return 0;
  }

  return (
    profileToEnergyConsumptionData.consumptionGrey.CAR *
    (carEnergy === carEnergies.ELECTRICITE.value
      ? profileToEnergyConsumptionData.IMPACT_OF_ELECTRIC_CAR_ON_GREY_ENERGY_CONSUMPTION_COEF
      : profileToEnergyConsumptionData.IMPACT_OF_FOSSIL_CAR_ON_GREY_ENERGY_CONSUMPTION_COEF) *
    getCarAgeCoeff(carAge)
  );
};

export const getGreyNumericConsumption = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { numericEquipment, numericWebTimeDay, numericVideoTimeDay } =
    personalization;

  return (
    profileToEnergyConsumptionData.consumptionGreyNumeric.EQUIPMENT_GLOBAL *
      (numericEquipment
        ? profileToEnergyConsumptionData.consumptionGreyNumeric.EQUIPMENT_TRUE
        : profileToEnergyConsumptionData.consumptionGreyNumeric
            .EQUIPMENT_FALSE) *
      1.5 +
    profileToEnergyConsumptionData.consumptionGreyNumeric.WEB_GLOBAL *
      (numericWebTimeDay
        ? profileToEnergyConsumptionData.consumptionGreyNumeric.WEB_TRUE
        : profileToEnergyConsumptionData.consumptionGreyNumeric.WEB_FALSE) *
      (numericVideoTimeDay
        ? profileToEnergyConsumptionData.consumptionGreyNumeric.VIDEO_TRUE
        : profileToEnergyConsumptionData.consumptionGreyNumeric.VIDEO_FALSE)
  );
};

export const getGreyOther = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { eatingLocal, clothingQuantity } = personalization;

  return (
    profileToEnergyConsumptionData.consumptionGreyOther.OTHER_GLOBAL *
    (1 -
      (eatingLocal
        ? profileToEnergyConsumptionData.consumptionGreyOther.LOCAL_TRUE
        : profileToEnergyConsumptionData.consumptionGreyOther.LOCAL_FALSE) -
      (clothingQuantity
        ? profileToEnergyConsumptionData.consumptionGreyOther.CLOTHING_TRUE
        : profileToEnergyConsumptionData.consumptionGreyOther.CLOTHING_FALSE))
  );
};

export const getGreyTransport = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { eatingLocal, clothingQuantity } = personalization;

  return (
    profileToEnergyConsumptionData.consumptionGreyTransport.GLOBAL *
    (1 -
      (eatingLocal
        ? profileToEnergyConsumptionData.consumptionGreyTransport.LOCAL_TRUE
        : profileToEnergyConsumptionData.consumptionGreyTransport.LOCAL_FALSE) -
      (clothingQuantity
        ? profileToEnergyConsumptionData.consumptionGreyTransport.CLOTHING_TRUE
        : profileToEnergyConsumptionData.consumptionGreyTransport
            .CLOTHING_FALSE))
  );
};
