import { Box, useTheme } from "@mui/material";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";

import { formatBudget, formatProductionGw } from "@/lib/formatter";

import { Button } from "@/modules/common/components/Button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/modules/common/components/Carousel";
import { Dialog } from "@/modules/common/components/Dialog";
import { Icon } from "@/modules/common/components/Icon";
import { IconButton } from "@/modules/common/components/IconButton";
import { Slider } from "@/modules/common/components/Slider";
import { ENERGY_SHIFT_TARGET_YEAR } from "@/modules/common/constants";
import { useDialog } from "@/modules/common/hooks/useDialog";
import { useCurrentPlayer } from "@/modules/play/context/hooks/player";
import { usePlay } from "@/modules/play/context/playContext";
import { computeTeamActionStats } from "@/modules/play/utils/production";
import { useTranslation } from "@/modules/translations";
import { TeamAction } from "@/utils/types";

export { TeamActionsContent };

function TeamActionsContent({ style }: { style?: React.CSSProperties }) {
  const { teamActionsAtCurrentStep } = useCurrentPlayer();

  return (
    <Box style={style}>
      <Carousel
        className="relative"
        opts={{
          loop: false,
          // Disable drag because interferes with the sliders on the production mean cards.
          watchDrag: false,
        }}
      >
        {teamActionsAtCurrentStep.length >= 2 && (
          <>
            <div className="!absolute left-0 top-1/2 z-10 hidden lg:flex">
              <CarouselPrevious
                className="!relative top-0 right-0 bottom-0 left-0 h-fit w-fit translate-x-0 translate-y-0"
                iconName="arrow-left"
              />
            </div>
            <div className="!absolute right-0 top-1/2 z-10 hidden lg:flex">
              <CarouselNext
                className="!relative top-0 right-0 bottom-0 left-0 h-fit w-fit translate-x-0 translate-y-0"
                iconName="arrow-right"
              />
            </div>
            <div className="mb-2 flex items-center justify-center gap-8 lg:hidden">
              <CarouselPrevious
                className="!relative left-0 top-0 right-0 bottom-0 flex h-fit w-fit translate-x-0 translate-y-0"
                iconName="arrow-left"
              />
              <CarouselNext
                className="!relative top-0 left-0 right-0 bottom-0 flex w-fit translate-x-0 translate-y-0"
                iconName="arrow-right"
              />
            </div>
          </>
        )}

        <CarouselContent>
          {teamActionsAtCurrentStep
            .map((teamAction) => (
              <CarouselItem key={teamAction.actionId}>
                <div className="flex justify-center">
                  <ProductionMeanCard
                    className="lg:max-w-[calc(100%_-_200px)]"
                    teamAction={teamAction}
                  />
                </div>
              </CarouselItem>
            ))
            .filter((option): option is NonNullable<typeof option> =>
              Boolean(option),
            )}
        </CarouselContent>
      </Carousel>
    </Box>
  );
}

function ProductionMeanCard({
  teamAction,
  className,
}: {
  teamAction: TeamAction;
  className?: string;
}) {
  const { ready, t } = useTranslation([
    "common",
    "countries",
    "production-actions",
  ]);
  const theme = useTheme();
  const { productionActionById, updateTeam } = usePlay();
  const [cardState, setCardState] = useState<"description" | "production">(
    "production",
  );
  const [value, setValue] = useState(teamAction.value);
  const { isOpen, closeDialog, openDialog } = useDialog();

  const productionAction = productionActionById[teamAction.actionId];

  const productionValueI18n = useCallback(
    (productionValue: string | number) =>
      productionAction.unit === "percentage"
        ? t("unit.percentage", { value: productionValue })
        : t("unit.area.base", { value: productionValue }),
    [productionAction, t],
  );

  const { isCredible } = computeTeamActionStats(
    teamAction,
    productionActionById,
  );

  const credibilityI18n = useMemo(() => {
    if (!ready) {
      return [];
    }
    if (isCredible) {
      return t(
        `production-actions:production-action.fr.${productionAction.name}.value.credible`,
        {
          year: ENERGY_SHIFT_TARGET_YEAR,
          returnObjects: true,
        },
      );
    }
    return t(
      `production-actions:production-action.fr.${productionAction.name}.value.not-credible`,
      {
        year: ENERGY_SHIFT_TARGET_YEAR,
        returnObjects: true,
      },
    );
  }, [isCredible, productionAction, ready, t]);
  const actionSubdescription = t(
    `production-actions:production-action.fr.${productionAction.name}.action-subdescription` as any,
    "",
  );

  const localTeamAction = useMemo(
    () => ({ ...teamAction, value }),
    [teamAction, value],
  );
  const localStats = useMemo(
    () => computeTeamActionStats(localTeamAction, productionActionById),
    [localTeamAction, productionActionById],
  );
  const teamStats = useMemo(
    () => computeTeamActionStats(teamAction, productionActionById),
    [productionActionById, teamAction],
  );

  const handleValidateTeamChoice = () => {
    updateTeam({
      teamActions: [
        {
          id: teamAction.id,
          value: value,
        },
      ],
    });
  };

  return (
    <div
      className={clsx(
        className,
        "production-mean-card",
        "flex flex-col gap-8 rounded-lg border-[2px] border-white px-3 py-4 text-white shadow-lg",
      )}
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <div className="flex flex-col gap-4">
        <p className="production-mean-card__title text-center text-lg">
          {t(
            `production-actions:production-action.fr.${productionAction.name}.accordion.title`,
          )}
        </p>

        <div
          className={`flex h-[300px] items-center justify-center overflow-hidden rounded-lg bg-cover bg-center shadow-inner md:h-[500px]`}
          style={{
            backgroundImage: `url('/production-means/${productionAction.name}.webp')`,
          }}
        ></div>

        <div className="flex gap-2">
          <IconButton
            iconName="power"
            type="primary"
            onClick={() => setCardState("production")}
          />
          <IconButton
            iconName="description"
            type="primary"
            onClick={() => setCardState("description")}
          />
        </div>
      </div>

      {cardState === "production" && (
        <>
          <p className="uppercase opacity-60">
            {t("production-action-card.production-side.title")}
          </p>

          <div className="production-mean-card__action flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <p className="production-mean-card__action__description">
                {t(
                  `production-actions:production-action.fr.${productionAction.name}.action-description`,
                )}
              </p>
              {actionSubdescription && (
                <p className="text-sm opacity-60">{actionSubdescription}</p>
              )}
            </div>
            <Slider
              value={value}
              min={productionAction.min}
              max={productionAction.max}
              marks={[
                {
                  label: t("form.field.slide.team-value.label", {
                    value: productionValueI18n(teamAction.value.toFixed(1)),
                  }),
                  value: teamAction.value,
                },
              ]}
              step={0.1}
              labelFormatter={productionValueI18n}
              onChange={(value) => setValue(value)}
              ariaLabel={t(
                `production-actions:production-action.fr.${productionAction.name}.accordion.label-slider`,
              )}
            />
            <p className="production-mean-card__action__hint">
              <Icon name="information" />{" "}
              {t(
                `production-actions:production-action.fr.${productionAction.name}.action-hint`,
              )}
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <p className="uppercase opacity-60">
              {t(
                "production-action-card.production-side.installed-power-section.title",
              )}
            </p>
            <div className="h-[2px] w-full bg-white opacity-60"></div>

            <div className="flex flex-col gap-4">
              <div className="production-mean-card__power__me">
                <p className="opacity-60">
                  {t(
                    "production-action-card.production-side.installed-power-section.installed-by-me",
                    { year: ENERGY_SHIFT_TARGET_YEAR },
                  )}
                </p>
                <div className="grid grid-cols-2">
                  <div className="flex gap-1">
                    <Icon name="power" />
                    <p>
                      {t("unit.power.giga", {
                        value: formatProductionGw(localStats.powerNeedGw),
                      })}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <Icon name="budget" />
                    <p>
                      {t("unit.budget-per-day.base", {
                        value: formatBudget(localStats.cost),
                        symbol: t("countries:country.fr.currency.symbol"),
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="production-mean-card__power__team">
                <p className="opacity-60">
                  {t(
                    "production-action-card.production-side.installed-power-section.installed-by-team",
                    { year: ENERGY_SHIFT_TARGET_YEAR },
                  )}
                </p>
                <div className="grid grid-cols-2">
                  <div className="flex gap-1">
                    <Icon name="power" />
                    <p>
                      {t("unit.power.giga", {
                        value: formatProductionGw(teamStats.powerNeedGw),
                      })}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <Icon name="budget" />
                    <p>
                      {t("unit.budget-per-day.base", {
                        value: formatBudget(teamStats.cost),
                        symbol: t("countries:country.fr.currency.symbol"),
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="production-mean-card__power__today">
                <p className="opacity-60">
                  {t(
                    "production-action-card.production-side.installed-power-section.installed-today",
                  )}
                </p>
                <div className="grid grid-cols-2">
                  <div className="flex gap-1">
                    <Icon name="power" />
                    <p>
                      {t("unit.power.giga", {
                        value: formatProductionGw(
                          productionAction.currentYearPowerNeedGw,
                        ),
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <p className="uppercase opacity-60">
              {t(
                "production-action-card.production-side.credibility-section.title",
              )}
            </p>
            <div className="h-[2px] w-full bg-white opacity-60"></div>

            {credibilityI18n.map((text) => (
              <p key={text}>{text}</p>
            ))}
          </div>

          <div className="production-mean-card__actions-wrapper">
            <Button className="w-full" onClick={handleValidateTeamChoice}>
              {t("cta.validate-team-choice")}
            </Button>
          </div>
        </>
      )}
      {cardState === "description" && (
        <>
          <p className="uppercase opacity-60">
            {t("production-action-card.description-side.title")}
          </p>

          <div className="production-mean-card__action flex flex-col gap-3">
            <div className="production-mean-card__action__description__text flex flex-col gap-2">
              {t(
                `production-actions:production-action.fr.${productionAction.name}.description`,
                {
                  returnObjects: true,
                },
              ).map((text) => (
                <p key={text}>{text}</p>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <p className="uppercase opacity-60">
              {t("production-action-card.production-side.pros-section.title")}
            </p>
            <div className="h-[2px] w-full bg-white opacity-60"></div>
            <div className="production-mean-card__action__description__pros flex flex-col gap-2">
              <ul className="flex list-disc flex-col gap-1 pl-4">
                {t(
                  `production-actions:production-action.fr.${productionAction.name}.pros`,
                  {
                    returnObjects: true,
                  },
                ).map((text) => (
                  <li key={text}>
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <p className="uppercase opacity-60">
              {t("production-action-card.production-side.cons-section.title")}
            </p>
            <div className="h-[2px] w-full bg-white opacity-60"></div>
            <div className="production-mean-card__action__description__cons flex flex-col gap-2">
              <ul className="flex list-disc flex-col gap-1 pl-4">
                {t(
                  `production-actions:production-action.fr.${productionAction.name}.cons`,
                  {
                    returnObjects: true,
                  },
                ).map((text) => (
                  <li key={text}>
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="production-mean-card__actions-wrapper">
            <Button className="w-full" onClick={openDialog}>
              {t("production-action-card.production-side.cta.more-info")}
            </Button>
          </div>

          <Dialog
            open={isOpen}
            handleClose={closeDialog}
            content={t("page.player.team-actions.help.action")}
            actions={
              <>
                <Button type="secondary" onClick={closeDialog}>
                  {t("cta.thanks-for-help")}
                </Button>
                <Button
                  iconName="open-in-new-tab"
                  to={productionAction.helpCardLink}
                  onClick={closeDialog}
                >
                  {t("cta.open-info-card")}
                </Button>
              </>
            }
          />
        </>
      )}
    </div>
  );
}
