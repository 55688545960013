import { PlayerPageLayout } from "@/modules/play/PlayLayout/PlayerPageLayout";

import { Persona } from "./Persona";
import { PlayerHeaderGrid } from "./PlayerHeaderGrid";

export { PlayerHeaderGrid, PlayerPersonaPage };

function PlayerPersonaPage() {
  return <PlayerPageLayout header={<PlayerHeaderGrid />} body={<Persona />} />;
}
