import { AppBar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import LanguageSwitcher from "@/modules/common/components/LanguageSwitcher";

export default UnauthenticatedHeader;

function UnauthenticatedHeader() {
  const navigate = useNavigate();

  return (
    <AppBar color="primary" position="fixed">
      <div className="flex justify-between px-6 py-4">
        <img
          className="cursor-pointer	"
          style={{ height: 32, padding: 1 }}
          src="/logoWithName.png"
          alt=""
          onClick={() => navigate("/play")}
        />
        <div className="flex items-center gap-4">
          <LanguageSwitcher />
        </div>
      </div>
    </AppBar>
  );
}
