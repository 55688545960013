import type { CountriesData } from "@/modules/play/context/playContext";

export { countriesData };

const countriesData: { [k: string]: CountriesData } = {
  fr: {
    population: 65_000_000,
    budgetLeftToLiveOnPerDay: 13.7,
  },
} as const;
