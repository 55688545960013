import {
  CountriesData,
  ProductionFacilitiesData,
  ProfileToEnergyConsumptionData,
} from "@/modules/play/context/playContext";
import {
  computeMaterials,
  computeMetals,
  PhysicalResourceNeedDatum,
} from "@/modules/play/gameEngines/resourcesEngine";
import { PersoForm } from "@/modules/play/Personalization/models/form";
import {
  computeCarbonFootprint,
  computeCarbonProductionElectricMix,
} from "@/modules/play/utils/carbonFootprint";
import { computeEnergyProduction } from "@/modules/play/utils/production";
import { ProductionAction, TeamAction } from "@/utils/types";

import { ConsumptionDatum, getConsumptionFromProfile } from "./consumption";
import { computeIntermediateValues } from "./consumption/computing";
import { ProductionDatum } from "./production";
import { fillPersonalization } from "./utils";

export { buildInitialPersona };
export type { Persona };

interface Persona {
  budget: number;
  carbonFootprint: number;
  actionPoints: number;
  points: number;
  consumption: readonly ConsumptionDatum[];
  production: ProductionDatum[];
  productionDisplayed: ProductionDatum[];
  materials: PhysicalResourceNeedDatum[];
  materialsDisplayed: PhysicalResourceNeedDatum[];
  metals: PhysicalResourceNeedDatum[];
  metalsDisplayed: PhysicalResourceNeedDatum[];
}

const buildInitialPersona = (
  personalization: PersoForm,
  teamActions: TeamAction[],
  productionActionById: Record<number, ProductionAction>,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
  productionFacilitiesData: ProductionFacilitiesData,
  countriesData: CountriesData,
): Persona => {
  const formattedPersonalization = fillPersonalization(personalization);
  const intermediateValues = computeIntermediateValues(
    formattedPersonalization,
    profileToEnergyConsumptionData,
  );
  const consumption = getConsumptionFromProfile(
    formattedPersonalization,
    intermediateValues,
    profileToEnergyConsumptionData,
  );

  const production: ProductionDatum[] = Object.values(productionActionById).map(
    (productionAction) => {
      return {
        name: productionAction.name,
        type: productionAction.type,
        carbonType: productionAction.carbonType,
        revealOnStep: productionAction.revealOnStep,
        value: computeEnergyProduction(
          productionAction,
          productionAction.defaultTeamValue,
        ),
      };
    },
  );

  const productionDisplayed = production.filter((p) => !p.revealOnStep);

  const carbonProductionElectricMix = computeCarbonProductionElectricMix(
    production,
    productionFacilitiesData,
  );
  const carbonFootprint = computeCarbonFootprint(
    carbonProductionElectricMix,
    consumption as ConsumptionDatum[],
  );

  const materials = computeMaterials(
    production,
    teamActions,
    productionActionById,
    productionFacilitiesData,
  );
  const materialsDisplayed = computeMaterials(
    productionDisplayed,
    teamActions,
    productionActionById,
    productionFacilitiesData,
  );
  const metals = computeMetals(
    production,
    teamActions,
    productionActionById,
    productionFacilitiesData,
  );
  const metalsDisplayed = computeMetals(
    productionDisplayed,
    teamActions,
    productionActionById,
    productionFacilitiesData,
  );

  const persona: Persona = {
    budget: countriesData.budgetLeftToLiveOnPerDay,
    actionPoints: 0,
    points: 0,
    carbonFootprint,
    consumption,
    production,
    productionDisplayed,
    materials,
    materialsDisplayed,
    metals,
    metalsDisplayed,
  };

  return persona;
};
