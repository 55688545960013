import { filterOutDuplicates } from "@/modules/common/utils";
import { ProductionDatum } from "@/modules/persona/production";
import { sumFor } from "@/modules/persona/utils";
import { ProductionFacilitiesData } from "@/modules/play/context/playContext";
import {
  MaterialsType,
  MetalsType,
  ProductionAction,
  ProductionTypes,
  TeamAction,
} from "@/utils/types";

export { computeMaterials, computeMetals };

export interface PhysicalResourceNeedDatum {
  name: MaterialsType | MetalsType;
  type: ProductionTypes;
  value: number;
}

const computeMaterials = (
  production: ProductionDatum[],
  performedTeamActions: TeamAction[],
  productionActionById: Record<number, ProductionAction>,
  productionFacilitiesData: ProductionFacilitiesData,
): PhysicalResourceNeedDatum[] => {
  return computePhysicalResources(
    production,
    performedTeamActions,
    productionActionById,
    Object.keys(Object.values(productionFacilitiesData)[0].materials) as any,
    "materials",
    productionFacilitiesData,
  );
};

const computeMetals = (
  production: ProductionDatum[],
  performedTeamActions: TeamAction[],
  productionActionById: Record<number, ProductionAction>,
  productionFacilitiesData: ProductionFacilitiesData,
): PhysicalResourceNeedDatum[] => {
  return computePhysicalResources(
    production,
    performedTeamActions,
    productionActionById,
    Object.keys(Object.values(productionFacilitiesData)[0].metals) as any,
    "metals",
    productionFacilitiesData,
  );
};

const computePhysicalResources = (
  production: ProductionDatum[],
  performedTeamActions: TeamAction[],
  productionActionById: Record<number, ProductionAction>,
  resourceNames: MaterialsType[] | MetalsType[],
  resourceType: "materials" | "metals",
  productionFacilitiesData: ProductionFacilitiesData,
): PhysicalResourceNeedDatum[] => {
  const results = production
    .map((prod: ProductionDatum) => {
      const prodConstants = Object.values(productionFacilitiesData).find(
        (prodConfig) => prodConfig.name === prod.name,
      );
      const teamAction = performedTeamActions.find(
        (teamAction: TeamAction) =>
          productionActionById[teamAction.actionId]?.name === prod.name,
      );
      const productionAction =
        productionActionById[teamAction?.actionId || -1] || {};
      const powerNeed = productionAction.powerNeededKWh || 0;
      return resourceNames.map((resourceName: MaterialsType | MetalsType) => ({
        type: resourceName,
        prodType: prodConstants?.type || "",
        value:
          ((prodConstants &&
            prodConstants[resourceType][
              resourceName as MaterialsType & MetalsType
            ]) ||
            0) *
          prod.value *
          powerNeed,
      }));
    })
    .flat();

  const productionTypes = results
    .filter((result) => result.value !== 0)
    .map((result) => result.prodType)
    .filter(filterOutDuplicates) as ProductionTypes[];

  return productionTypes.flatMap((prodType) => {
    const filteredResults = results.filter(
      (resource) => resource.prodType === prodType,
    );
    return resourceNames.map((resource: MaterialsType | MetalsType) => ({
      name: resource,
      value: sumFor(filteredResults, resource),
      type: prodType,
    }));
  });
};
