import { buildInitialPersona } from "@/modules/persona/persona";
import { usePlay } from "@/modules/play/context/playContext";
import { buildPersona } from "@/modules/play/utils/persona";

import { useCurrentPlayer } from "./useCurrentPlayer";

export { usePersona };

function usePersona() {
  const {
    consumptionActionById,
    countriesData,
    game,
    productionActionById,
    productionFacilitiesData,
    profileToEnergyConsumptionData,
  } = usePlay();
  const { personalization, playerActions, teamActions } = useCurrentPlayer();

  const initialPersona = buildInitialPersona(
    personalization,
    teamActions,
    productionActionById,
    profileToEnergyConsumptionData,
    productionFacilitiesData,
    countriesData,
  );

  return buildPersona(
    game,
    personalization,
    initialPersona,
    playerActions,
    consumptionActionById,
    teamActions,
    productionActionById,
    profileToEnergyConsumptionData,
    productionFacilitiesData,
  );
}
