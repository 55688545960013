import { GlobalStyles, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { API_URL } from "@/modules/common/constants";
import { initErrorTracer } from "@/modules/error-handling/utils";
import { SettingsProvider } from "@/modules/settings/settingsProvider";
import { trpc } from "@/utils/trpc";

import App from "./App";
import { AlertProvider } from "./modules/alert";
import { AuthProvider } from "./modules/auth/authProvider";
import { LayoutProvider } from "./modules/Layout/context";
import { RootPlayProvider } from "./modules/play/context/playContext";
import { globalStyles, theme } from "./utils/theme";

initErrorTracer();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
);

function Bootstrap() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: "offlineFirst",
          },
          mutations: {
            networkMode: "offlineFirst",
          },
        },
      }),
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${API_URL}/trpc`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          }, // You can pass any HTTP headers you wish here
          async headers() {
            const headers: Record<string, string> = {};
            const token = localStorage.getItem("token");
            if (token) {
              headers.Authorization = `Bearer ${token}`;
            }
            return headers;
          },
        }),
      ],
    }),
  );

  return (
    <BrowserRouter>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyles styles={globalStyles} />
            <SettingsProvider>
              <AlertProvider>
                <AuthProvider>
                  <LayoutProvider>
                    <RootPlayProvider>
                      <App />
                    </RootPlayProvider>
                  </LayoutProvider>
                </AuthProvider>
              </AlertProvider>
            </SettingsProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </BrowserRouter>
  );
}
