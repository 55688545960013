import InvertColorsIcon from "@mui/icons-material/InvertColors";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const OgreHeader = () => {
  return (
    <div>
      <Link to="/">
        <Typography
          className="flex items-center justify-center"
          component="div"
        >
          <InvertColorsIcon color="secondary" style={{ fontSize: 60 }} />
          <span className="text-5xl font-thin text-white">OGRE</span>
        </Typography>
      </Link>
    </div>
  );
};

export default OgreHeader;
