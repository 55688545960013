import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/modules/common/components/Button";
import { Checkbox } from "@/modules/common/components/ui/Checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogMain,
  DialogTitle,
} from "@/modules/common/components/ui/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/modules/common/components/ui/Form";
import { Input } from "@/modules/common/components/ui/Input";
import { Textarea } from "@/modules/common/components/ui/Textarea";
import { useDialog } from "@/modules/common/hooks/useDialog";
import { useTranslation } from "@/modules/translations/useTranslation";
import { trpc } from "@/utils/trpc";

export { CreateGameDialog };

function CreateGameDialog({
  onGameCreated,
}: {
  onGameCreated?: () => void | Promise<void>;
}) {
  const { t } = useTranslation(["admin", "common"]);
  const formRef = useRef<HTMLFormElement>(null);
  const { isOpen, openDialog, closeDialog, setIsOpen } = useDialog();

  const formSchema = useMemo(() => {
    return z.object({
      name: z
        .string({ required_error: t("common:form.error.required") })
        .min(1, t("common:form.error.required")),
      description: z.string().optional(),
      date: z
        .string({ required_error: t("common:form.error.required") })
        .min(1, t("common:form.error.required")),
      isTest: z.boolean().optional(),
    });
  }, [t]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      date: "",
      isTest: false,
    },
  });

  const createMutation = trpc.game.create.useMutation();

  const submit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true }),
    );
  };

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    await createMutation.mutateAsync(
      {
        name: values.name,
        description: values.description,
        date: new Date(values.date).toISOString(),
        isTest: values.isTest,
      },
      {
        onSuccess: async () => {
          await onGameCreated?.();
          closeDialog();
        },
      },
    );
  };

  return (
    <>
      <Button iconName="create" onClick={openDialog}>
        {t("common:cta.create-game")}
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("admin:dialog.create-game.title")}</DialogTitle>
            <DialogDescription>
              {t("admin:dialog.create-game.description")}
            </DialogDescription>
          </DialogHeader>

          <DialogMain className="py-4">
            <Form {...form}>
              <form
                className="flex flex-col gap-8"
                ref={formRef}
                onSubmit={async (e) => {
                  e.stopPropagation();
                  await form.handleSubmit(handleSubmit)(e);
                }}
              >
                <div className="flex flex-col gap-4">
                  <FormField
                    name="name"
                    control={form.control}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>
                          {t("common:form.field.game-name.label")}
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            required
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="description"
                    control={form.control}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>
                          {t("common:form.field.game-description.label")}
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} rows={4} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="date"
                    control={form.control}
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>
                          {t("common:form.field.game-date.label")}
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="datetime-local"
                            required
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  name="isTest"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <div className="flex items-center gap-2">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel>
                          {t("common:form.field.game-is-test.label")}
                        </FormLabel>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
          </DialogMain>

          <DialogFooter className="w-full !justify-end">
            <Button
              iconName="create"
              onClick={submit}
              loading={createMutation.isLoading}
            >
              {t("common:cta.create")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
