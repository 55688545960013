import { DAYS_IN_YEAR } from "@/constants/common";
import { ProfileToEnergyConsumptionData } from "@/modules/play/context/playContext";
import {
  carAges,
  houseEnergies,
  hygieneCleaning,
  PersoForm,
  showerTimes,
} from "@/modules/play/Personalization/models/form";

export const getHeatingConsumption = (
  personalization: PersoForm,
  heatingConsumptionInvoiceCoeff: number,
) => {
  const { heatingInvoice, heatingConsumption, numberAdults } = personalization;
  if (heatingInvoice === 0) {
    return heatingConsumption / (DAYS_IN_YEAR * numberAdults);
  }

  if (heatingConsumption === 0) {
    return heatingConsumptionInvoiceCoeff / (DAYS_IN_YEAR * numberAdults);
  }
  return (
    (heatingConsumptionInvoiceCoeff + heatingConsumption) /
    2 /
    (DAYS_IN_YEAR * numberAdults)
  );
};

export const getHeatingConsumptionInvoiceCoeff = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const { heatingInvoice, heatingEnergy } = personalization;

  let energyCostPerKwh = 0;
  if (heatingEnergy === houseEnergies.GAZ.value) {
    energyCostPerKwh = profileToEnergyConsumptionData.heatingEnergyCoeffs.GAZ;
  } else if (heatingEnergy === houseEnergies.FIOUL.value) {
    energyCostPerKwh = profileToEnergyConsumptionData.heatingEnergyCoeffs.FIOUL;
  } else if (heatingEnergy === houseEnergies.BOIS.value) {
    energyCostPerKwh = profileToEnergyConsumptionData.heatingEnergyCoeffs.BOIS;
  } else if (heatingEnergy === houseEnergies.ELECTRICITE.value) {
    energyCostPerKwh =
      profileToEnergyConsumptionData.heatingEnergyCoeffs.ELECTRICITE;
  } else {
    throw new Error(
      `Invalid personalization value for heating energy: ${heatingEnergy}`,
    );
  }

  return heatingInvoice / energyCostPerKwh;
};

const carAgeToCoeff = {
  [carAges.MOINS_5.value]: 3,
  [carAges.CINQ_DIX.value]: 2,
  [carAges.DIX_QUINZE.value]: 1,
  [carAges.PLUS_15.value]: 1,
};

const showerTimesToCoeff = {
  [showerTimes.MOINS_5.value]: 0.5,
  [showerTimes.CINQ_DIX.value]: 0.75,
  [showerTimes.DIX_QUINZE.value]: 1.25,
  [showerTimes.PLUS_15.value]: 2,
};

const getCoeff = (configName: string, mapping: any) => (key: string) => {
  const coeff = mapping[key];
  if (coeff == null) {
    throw new Error(`Invalid personalization value for ${configName}: ${key}`);
  }

  return coeff;
};

export const getCarAgeCoeff = getCoeff("car age", carAgeToCoeff);
export const getShowerTimeCoeff = getCoeff("shower time", showerTimesToCoeff);

export const getWhiteProductsCoeff = (
  personalization: PersoForm,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  const {
    cookingKettle,
    cookingPlateTime,
    cookingOvenTime,
    cleaningWashingTime,
    cleaningDryerTime,
    cleaningDishwasherTime,
    refrigeratorNumber,
    freezerNumber,
  } = personalization;

  const result = [];
  if (cookingKettle) {
    result.push(
      profileToEnergyConsumptionData.whiteProductsConstants.COOKING_KETTLE,
    );
  }

  result.push(
    cookingPlateTime *
      profileToEnergyConsumptionData.whiteProductsConstants.COOKING_PLATE,
  );
  result.push(
    cookingOvenTime *
      profileToEnergyConsumptionData.whiteProductsConstants.COOKING_OVEN,
  );
  result.push(
    cleaningWashingTime *
      profileToEnergyConsumptionData.whiteProductsConstants.CLEANING_WASHING,
  );
  result.push(
    cleaningDryerTime *
      profileToEnergyConsumptionData.whiteProductsConstants.CLEANING_DRYER,
  );
  result.push(
    cleaningDishwasherTime *
      profileToEnergyConsumptionData.whiteProductsConstants.CLEANING_DISHWASHER,
  );
  result.push(
    refrigeratorNumber *
      profileToEnergyConsumptionData.whiteProductsConstants.REFRIGERATOR,
  );
  result.push(
    freezerNumber *
      profileToEnergyConsumptionData.whiteProductsConstants.FREEZER,
  );
  result.push(profileToEnergyConsumptionData.whiteProductsConstants.GLOBAL);
  return result.reduce((a, b) => a + b, 0);
};

export const getShowerBathCoeff = (personalization: PersoForm) => {
  const { showerBath, showerNumber, showerTime } = personalization;
  if (showerBath === hygieneCleaning.BAINS.value) {
    return 5;
  }
  return showerNumber * getShowerTimeCoeff(showerTime) * 2.8;
};
