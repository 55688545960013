import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  defaultLang,
  getLangFromLocalStorage,
  Lang,
  LOCALSTORAGE_LANG_KEY,
} from "@/modules/settings/utils";
import { changeLanguage } from "@/modules/translations";

export { SettingsProvider, useSettings };

interface SettingsContext {
  lang: Lang;
  setLang: (lang: Lang) => void;
}

const defaultContext: SettingsContext = {
  lang: defaultLang,
  setLang: () => {},
};

const SettingsContextImpl = createContext<SettingsContext>(defaultContext);
const useSettings = () => useContext<SettingsContext>(SettingsContextImpl);

function SettingsProvider({ children }: { children: ReactNode }) {
  const [lang, _setLang] = useState(defaultContext.lang);

  const setLang = useCallback(
    (lang: Lang) => {
      changeLanguage(lang);
      _setLang(lang);
      localStorage.setItem(LOCALSTORAGE_LANG_KEY, lang);
    },
    [_setLang],
  );

  useEffect(() => {
    setLang(getLangFromLocalStorage(defaultContext.lang));
  }, [setLang]);

  const context = useMemo(
    () => ({
      lang,
      setLang,
    }),
    [lang, setLang],
  );

  return (
    <SettingsContextImpl.Provider value={context}>
      {children}
    </SettingsContextImpl.Provider>
  );
}
