import { sum } from "lodash";

import { getValueByName, sumAllValues } from "@/modules/persona";
import { ConsumptionDatum } from "@/modules/persona/consumption";
import { ProductionDatum } from "@/modules/persona/production";
import { ProductionFacilitiesData } from "@/modules/play/context/playContext";

export { computeCarbonFootprint, computeCarbonProductionElectricMix };

function computeCarbonProductionElectricMix(
  production: ProductionDatum[],
  productionFacilitiesData: ProductionFacilitiesData,
) {
  const globalProduction = parseFloat(sumAllValues(production));
  return (
    sum(
      Object.values(productionFacilitiesData).map(
        ({ name, electricMixCarbonProduction }) =>
          getValueByName(production, name) * electricMixCarbonProduction,
      ),
    ) / globalProduction
  );
}

function computeCarbonFootprint(
  carbonProductionElectricMix: number,
  consumption: ConsumptionDatum[],
) {
  return sum(
    consumption.map(({ value, carbonProduction, carbonProductionPerKwh }) => {
      if (carbonProduction === "electric") {
        return (carbonProductionElectricMix * value) / 1000;
      }
      return ((carbonProductionPerKwh || 0) * value) / 1000;
    }),
  );
}
