import { Navigate, Route, Routes } from "react-router-dom";

import OgreHeader from "@/modules/common/components/OgreHeader";
import UnauthenticatedHeader from "@/modules/common/components/UnauthenticatedHeader";
import MagicLink from "@/modules/magic-link";
import SignIn from "@/modules/sign-in/SignIn";
import Signup from "@/modules/signup";
import { theme } from "@/utils/theme";

export { UnauthenticatedApp };

function UnauthenticatedApp() {
  return (
    <div
      className="flex min-h-screen flex-col px-4 pb-12"
      style={{ backgroundColor: theme.palette.backgrounds.page }}
    >
      <UnauthenticatedHeader />
      <div
        className="mx-auto mt-28 flex w-full flex-col gap-8 rounded-xl p-4 py-8 md:w-fit md:p-8 md:pt-12"
        style={{ background: theme.palette.primary.main }}
      >
        <OgreHeader />
        <div className="flex flex-col items-center">
          <Routes>
            <Route path="/" element={<MagicLink />} />
            <Route path="/magic-link" element={<MagicLink />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
