import { resources } from "@/modules/translations/resources";

export { defaultLang, getLangFromLocalStorage, LOCALSTORAGE_LANG_KEY };
export type { Lang };

const LOCALSTORAGE_LANG_KEY = "lang";

const defaultLang: Lang = "fr";

const getLangFromLocalStorage = (defaultLanguage?: Lang): Lang =>
  (localStorage.getItem(LOCALSTORAGE_LANG_KEY) as Lang | null) ||
  defaultLanguage ||
  defaultLang;

type Lang = keyof typeof resources;
