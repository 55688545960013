import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { Admins } from "@/modules/administration/Admins";
import { Game, Games } from "@/modules/administration/Games";
import { FormVerification } from "@/modules/administration/Games/Game/FormVerification";
import { Layout as AdministrationLayout } from "@/modules/administration/Layout/Layout";
import { Players } from "@/modules/administration/Players/Players";
import { Settings } from "@/modules/administration/Teachers/Settings";
import { Teachers } from "@/modules/administration/Teachers/Teachers";
import { useAuth } from "@/modules/auth/authProvider";
import { GameConsolePage } from "@/modules/play/GameConsole";
import { MyGames } from "@/modules/play/MyGames";
import { PersonalizationChoice } from "@/modules/play/Personalization/PersonalizationChoice";
import { PersonalizationForm } from "@/modules/play/Personalization/PersonalizationForm";
import { PersonalizationLayout } from "@/modules/play/Personalization/PersonalizationLayout";
import { PersonalizationPredefinedPersona } from "@/modules/play/Personalization/PersonalizationPredefinedPersona";
import { PlayerActionsPage } from "@/modules/play/playerActions";
import { PlayerPersonaPage } from "@/modules/play/PlayerPersona/PlayerPersonaPage";
import { PlayLayout } from "@/modules/play/PlayLayout/PlayLayout";
import { PlayerStatisticsPage } from "@/modules/play/Stats/PlayerStatisticsPage";
import { RouteGuard } from "@/modules/router/components/RouteGuard";
import { gameConsoleGuard } from "@/modules/router/guards/gameConsoleGuard";

export { AuthenticatedApp };

function AuthenticatedApp() {
  const { user } = useAuth();
  if (user === null) {
    throw new Error("User must be authenticated");
  }
  return (
    <Routes>
      <Route path="administration" element={<AdministrationLayout />}>
        <Route path="games" element={<Games />} />
        <Route path="games/:id" element={<Outlet />}>
          <Route path="information" element={<Game />} />
          <Route path="players" element={<Game />} />
          <Route
            path="players/form-verification"
            element={<FormVerification />}
          />
          <Route path="teams" element={<Game />} />
          <Route path="" element={<Navigate to="information" />} />
          <Route path="*" element={<Navigate to="information" />} />
        </Route>
        <Route path="admins" element={<Admins />} />
        <Route path="players" element={<Players />} />
        <Route path="teachers" element={<Teachers />} />
        <Route path="settings" element={<Settings />} />
        <Route path="" element={<Navigate to="games" />} />
        <Route path="*" element={<Navigate to="games" />} />
      </Route>
      <Route path="play" element={<PlayLayout />}>
        <Route path="my-games" element={<MyGames />} />
        <Route path="games/:id" element={<PlayerPersonaPage />} />
        <Route path="games/:id/persona" element={<PlayerPersonaPage />} />
        <Route
          path="games/:id/persona/stats"
          element={<PlayerStatisticsPage />}
        />
        <Route path="games/:id/personalize" element={<PersonalizationLayout />}>
          <Route path="choice" element={<PersonalizationChoice />} />
          <Route path="form" element={<PersonalizationForm />} />
          <Route path="oilgre" element={<PersonalizationPredefinedPersona />} />
          <Route path="" element={<Navigate to="choice" />} />
        </Route>
        <Route
          path="games/:id/persona/actions"
          element={<PlayerActionsPage />}
        />
        <Route
          path="games/:id/console"
          element={<RouteGuard guard={gameConsoleGuard} />}
        >
          <Route path="" element={<GameConsolePage />} />
          <Route path="*" element={<GameConsolePage />} />
        </Route>
        <Route path="" element={<Navigate to="my-games" />} />
        <Route path="*" element={<Navigate to="my-games" />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/play" />} />
    </Routes>
  );
}
