import { PlayerHeaderGrid } from "@/modules/play/PlayerPersona/PlayerHeaderGrid";
import { PlayerPageLayout } from "@/modules/play/PlayLayout/PlayerPageLayout";

import { StatsGraphs } from "./StatsGraphs";

export { PlayerStatisticsPage };

function PlayerStatisticsPage() {
  return (
    <PlayerPageLayout header={<PlayerHeaderGrid />} body={<StatsGraphs />} />
  );
}
