import { Alerts } from "./modules/alert";
import { useAuth } from "./modules/auth/authProvider";
import { ErrorBoundary } from "./modules/error-handling/ErrorBoundary";
import { AuthenticatedApp } from "./modules/router/components/AuthenticatedApp";
import { UnauthenticatedApp } from "./modules/router/components/UnauthenticatedApp";

export default App;

function App() {
  const { user } = useAuth();

  return (
    <ErrorBoundary>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <Alerts />
    </ErrorBoundary>
  );
}
