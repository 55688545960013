import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import { Typography } from "@/modules/common/components/Typography";
import { useSettings } from "@/modules/settings/settingsProvider";

export default LanguageSwitcher;

function LanguageSwitcher() {
  const { lang, setLang } = useSettings();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const langOptions = useMemo(() => {
    return [
      {
        lang: "en",
        label: "English",
      },
      {
        lang: "fr",
        label: "Français",
      },
    ] as const;
  }, []);

  const langToFlagCode = useCallback((lang: string) => {
    if (lang === "en") {
      return "gb";
    }
    return lang;
  }, []);

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{ p: 0 }}
      >
        <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white">
          <img
            className="rounded-sm"
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${langToFlagCode(
              lang,
            ).toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${langToFlagCode(
              lang,
            ).toLowerCase()}.png 2x`}
            alt=""
          />
        </div>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ mt: 4 }}
      >
        {langOptions.map((option) => (
          <MenuItem
            key={option.lang}
            onClick={() => {
              setLang(option.lang);
              handleClose();
            }}
          >
            <img
              className="rounded-sm"
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${langToFlagCode(
                option.lang,
              ).toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${langToFlagCode(
                option.lang,
              ).toLowerCase()}.png 2x`}
              alt=""
            />
            <Typography sx={{ ml: 2 }}>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
