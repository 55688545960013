import { deepFreeze } from "@/lib/array";

import { carbonPerKwh } from "@/modules/play/constants/consumption";
import { ProfileToEnergyConsumptionData } from "@/modules/play/context/playContext";
import {
  IntermediateValues,
  PersoForm,
} from "@/modules/play/Personalization/models/form";

import {
  getAirConditionningConsumption,
  getBrownGoodsConsumption,
  getCleanCookConsumption,
  getElectricCarConsumption,
  getFoodConsumption,
  getFossilCarConsumption,
  getFossilHeatingConsumption,
  getGreyCarConsumption,
  getGreyNumericConsumption,
  getGreyOther,
  getGreyTransport,
  getLightConsumption,
  getNoCarbonHeatingConsumption,
  getPlaneConsumption,
  getTrainConsumption,
} from "./consumption/computing";

export { getConsumptionFromProfile };
export type { ConsumptionDatum, ConsumptionName, ConsumptionType };

interface ConsumptionDatum {
  name: ConsumptionName;
  type: ConsumptionType;
  // In kWh / day
  value: number;
  carbonProduction: CarbonProduction;
  carbonProductionPerKwh?: number;
}
type ConsumptionName =
  | "fossilCar"
  | "fossilHeating"
  | "plane"
  | "airConditionning"
  | "brownGoods"
  | "cleanCook"
  | "electricCar"
  | "light"
  | "noCarbonHeating"
  | "train"
  | "food"
  | "greyCar"
  | "greyHouse"
  | "greyNumeric"
  | "greyOther"
  | "greyTransport"
  | "servicePublic";
type ConsumptionType = "fossil" | "grey" | "mixte" | "renewable";
type CarbonProduction = "electric" | "fossil";

const getConsumptionFromProfile = (
  personalization: PersoForm,
  intermediateValues: IntermediateValues,
  profileToEnergyConsumptionData: ProfileToEnergyConsumptionData,
) => {
  return deepFreeze([
    {
      name: "fossilCar",
      carbonProductionPerKwh: carbonPerKwh.FOSSIL_CAR,
      value: getFossilCarConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "fossil",
      type: "fossil",
    },
    {
      name: "fossilHeating",
      carbonProductionPerKwh: carbonPerKwh.FOSSIL_HEATING,
      value: getFossilHeatingConsumption(personalization, intermediateValues),
      carbonProduction: "fossil",
      type: "fossil",
    },
    {
      name: "plane",
      carbonProductionPerKwh: carbonPerKwh.PLANE,
      value: getPlaneConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "fossil",
      type: "fossil",
    },
    {
      name: "airConditionning",
      value: getAirConditionningConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "brownGoods",
      value: getBrownGoodsConsumption(
        personalization,
        intermediateValues,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "cleanCook",
      value: getCleanCookConsumption(intermediateValues),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "electricCar",
      value: getElectricCarConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "light",
      value: getLightConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "noCarbonHeating",
      value: getNoCarbonHeatingConsumption(personalization, intermediateValues),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "train",
      value: getTrainConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "electric",
      type: "renewable",
    },
    {
      name: "food",
      value: getFoodConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "electric",
      type: "mixte",
    },
    {
      name: "greyCar",
      carbonProductionPerKwh: carbonPerKwh.GREY_CAR,
      value: getGreyCarConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "fossil",
      type: "grey",
    },
    {
      name: "greyHouse",
      carbonProductionPerKwh: carbonPerKwh.GREY_HOUSE,
      value: profileToEnergyConsumptionData.consumptionGrey.HOUSE,
      carbonProduction: "fossil",
      type: "grey",
    },
    {
      name: "greyNumeric",
      carbonProductionPerKwh: carbonPerKwh.GREY_NUMERIC,
      value: getGreyNumericConsumption(
        personalization,
        profileToEnergyConsumptionData,
      ),
      carbonProduction: "fossil",
      type: "grey",
    },
    {
      name: "greyOther",
      carbonProductionPerKwh: carbonPerKwh.GREY_OTHER,
      value: getGreyOther(personalization, profileToEnergyConsumptionData),
      carbonProduction: "fossil",
      type: "grey",
    },
    {
      name: "greyTransport",
      carbonProductionPerKwh: carbonPerKwh.GREY_TRANSPORT,
      value: getGreyTransport(personalization, profileToEnergyConsumptionData),
      carbonProduction: "fossil",
      type: "grey",
    },
    {
      name: "servicePublic",
      carbonProductionPerKwh: carbonPerKwh.PUBLIC_SERVICE,
      value: profileToEnergyConsumptionData.consumptionGrey.PUBLIC_SERVICE,
      carbonProduction: "fossil",
      type: "grey",
    },
  ]) as readonly ConsumptionDatum[];
};
