import { IconButton, Menu, MenuItem } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/modules/auth/authProvider";
import { Icon } from "@/modules/common/components/Icon";
import { Typography } from "@/modules/common/components/Typography";
import { useTranslation } from "@/modules/translations";

export default LoggedUser;

function LoggedUser() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setToken } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{ p: 0 }}
      >
        <div
          className={clsx(
            "flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white",
          )}
        >
          <Icon name="user-account" />
        </div>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ mt: 4 }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/play");
          }}
        >
          <Icon name="play" />
          <Typography sx={{ ml: 2 }}>{t("cta.go-to-games-list")}</Typography>
        </MenuItem>
        <AdministrationMenuItem handleClose={handleClose} />
        <MenuItem
          onClick={() => {
            setToken(null);
            queryClient.invalidateQueries(["logged-user"]);
            handleClose();
          }}
        >
          <Icon name="logout" />
          <Typography sx={{ ml: 2 }}>{t("cta.logout")}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

function AdministrationMenuItem({ handleClose }: { handleClose: () => void }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissions } = useAuth();

  if (!permissions.canAccessAdminPanel) {
    return <></>;
  }
  return (
    <MenuItem
      onClick={() => {
        handleClose();
        navigate("/administration");
      }}
    >
      <Icon name="admin-panel" />
      <Typography sx={{ ml: 2 }}>{t("cta.go-to-admin-home")}</Typography>
    </MenuItem>
  );
}
