export { profileToEnergyConsumptionData };

const profileToEnergyConsumptionData = {
  fr: {
    heatingEnergyCoeffs: {
      GAZ: 0.07,
      FIOUL: 0.087,
      BOIS: 0.07,
      ELECTRICITE: 0.15,
    },
    whiteProductsConstants: {
      COOKING_KETTLE: 0.726,
      COOKING_PLATE: 3,
      COOKING_OVEN: 3,
      CLEANING_WASHING: 3,
      CLEANING_DRYER: 3,
      CLEANING_DISHWASHER: 2.5,
      REFRIGERATOR: 0.48,
      FREEZER: 2.16,
      GLOBAL: 0.396,
    },
    lightingConstants: {
      LED: 2,
      OTHER: 4,
    },
    numericEquipment: {
      YES: 7.5,
      NO: 5,
    },
    transportCoeffs: {
      ELECTRIC_CAR: 0.45,
      MEAN_FOSSIL_CAR_CONSUMPTION_IN_L_PER_KM: 0.07,
      TRAIN: 0.133,
      PLANE: 0.41,
    },
    consumptionGrey: {
      HOUSE: 3,
      PUBLIC_SERVICE: 7.97,
      CAR: 14,
    },
    consumptionFood: {
      VEGAN: 1.5,
      VEGETABLES: 1.5,
      DAIRIES: 1.5,
      EGGS: 1,
      MEAT: 4,
      TIN_DRINK: 0.6,
      ZERO_WASTE: 4,
      CAT: 2,
      DOG: 9,
      HORSE: 17,
      GLOBAL: 2.9,
    },
    consumptionGreyOther: {
      OTHER_GLOBAL: 36,
      LOCAL_TRUE: 0.15,
      LOCAL_FALSE: 0,
      CLOTHING_TRUE: 0,
      CLOTHING_FALSE: 0.15,
    },
    consumptionGreyNumeric: {
      EQUIPMENT_GLOBAL: 3,
      EQUIPMENT_TRUE: 1.5,
      EQUIPMENT_FALSE: 1,
      WEB_GLOBAL: 3,
      WEB_TRUE: 1.15,
      WEB_FALSE: 1,
      VIDEO_TRUE: 1.15,
      VIDEO_FALSE: 1,
    },
    consumptionGreyTransport: {
      GLOBAL: 12,
      LOCAL_TRUE: 0.1,
      LOCAL_FALSE: 0,
      CLOTHING_TRUE: 0,
      CLOTHING_FALSE: 0.1,
    },
    AIR_CONDITIONING_POWER: 0.6,
    GASOLINE_ENERGY_DENSITY_IN_KWH_PER_L: 10,
    IMPACT_OF_CHANGING_WOODWORK_WINDOWS_ON_HEATING_CONSUMPTION_COEF: 0.9,
    IMPACT_OF_ELECTRIC_CAR_ON_GREY_ENERGY_CONSUMPTION_COEF: 1.2,
    IMPACT_OF_FOSSIL_CAR_ON_GREY_ENERGY_CONSUMPTION_COEF: 1,
    IMPACT_OF_EFFICIENT_ELECTRIC_WOOD_HEATING_SYSTEM_ON_HEATING_CONSUMPTION_COEF: 0.4,
    IMPACT_OF_EFFICIENT_HOUSEHOLD_APPLIANCES_ON_WHITE_GOODS_CONSUMPTION_COEF: 0.75,
    IMPACT_OF_EFFICIENT_VENTILATION_SYSTEM_ON_HEATING_CONSUMPTION_COEF: 0.8,
    IMPACT_OF_HEATING_HOUSE_19_DEGREES_MAX_ON_HEATING_CONSUMPTION_COEF: 0.86,
    IMPACT_OF_INSULATING_ROOF_ON_HEATING_CONSUMPTION_COEF: 0.75,
    IMPACT_OF_INSULATING_WALLS_ON_HEATING_CONSUMPTION_COEF: 0.75,

    IMPACT_OF_UNPLUGGING_APPLIANCES_ON_STANDBY_ON_BROWN_GOODS_CONSUMPTION_TERM:
      -0.6,
    IMPACT_OF_UNPLUGGING_CHARGERS_ON_BROWN_GOODS_CONSUMPTION_TERM: -0.01,
  },
} as const;
