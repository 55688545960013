import { useTheme } from "@mui/material";

import { PlayBox } from "@/modules/play/Components";
import { useCurrentStep } from "@/modules/play/context/playContext";
import { PlayerHeaderGrid } from "@/modules/play/PlayerPersona/PlayerHeaderGrid";
import { PlayerPageLayout } from "@/modules/play/PlayLayout/PlayerPageLayout";

import { PlayerActionsContent } from "./PlayerActionsContent";
import { PlayerActionsHeader } from "./PlayerActionsHeader";
import { SynthesisPage } from "./SynthesisPage";
import { TeamActionsContent } from "./TeamActionsContent";
import { TeamActionsHeader } from "./TeamActionsHeader";
import { ValidateActions } from "./Validation";

export { PlayerActionsPage };

function PlayerActionsPage() {
  const currentStep = useCurrentStep();

  if (!currentStep) {
    return null;
  }

  if (currentStep.id === "final-situation") {
    return <SynthesisPage />;
  }

  return currentStep.type === "production" ? (
    <TeamActionsLayout />
  ) : (
    <PlayerActionsLayout />
  );
}

function PlayerActionsLayout() {
  return (
    <PlayerPageLayout
      header={<PlayerHeaderGrid additionalActions={<ValidateActions />} />}
      body={
        <PlayBox
          display="flex"
          flexDirection="column"
          gap={4}
          header={<PlayerActionsHeader />}
          headerSticky
        >
          <PlayerActionsContent />
        </PlayBox>
      }
    />
  );
}

function TeamActionsLayout() {
  const theme = useTheme();

  return (
    <PlayerPageLayout
      header={<PlayerHeaderGrid />}
      body={
        <div className="flex flex-col gap-4">
          <div
            className="rounded-lg border-[2px] border-white px-4 py-2 text-white shadow"
            style={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <TeamActionsHeader />
          </div>
          <div>
            <TeamActionsContent />
          </div>
        </div>
      }
    />
  );
}
