import isNaN from "lodash/isNaN";
import { useParams } from "react-router-dom";

import { pipe } from "@/lib/fp";

import {
  booleanChoices,
  Condition,
  ConditionAND,
  ConditionSimple,
  formValues,
  PersoForm,
  Question,
} from "@/modules/play/Personalization/models/form";
import { buildChoices } from "@/modules/play/Personalization/utils/choices";
import { compare } from "@/modules/play/Personalization/utils/formValidation";

import { Row } from "./FormVerification";

export {
  formatValueOptions,
  getBooleanOptions,
  getCellCredibility,
  getDropdownOptions,
  isSuspicious,
  numericParser,
  useGameId,
};

const useGameId = () => {
  const { id } = useParams();
  if (!id) throw new Error("game id must be defined");
  const gameId = +id;
  return gameId;
};

const getBooleanOptions = () => {
  return booleanChoices.map((choice) => ({
    value: choice.value,
    label: choice.description,
  }));
};

const getDropdownOptions = (
  values: Record<
    string,
    { value: string; description: string; order?: number }
  >,
) => {
  return buildChoices(values).map((choice) => ({
    value: choice.value,
    label: choice.description,
  }));
};

const formatValueOptions = ({
  value,
  field,
  api,
}: {
  value: any;
  field: string;
  api: any;
}) => {
  const colDef = api.getColumn(field);
  const option = colDef.valueOptions.find(
    ({ value: optionValue }: { value: any }) => value === optionValue,
  );
  return option ? option.label : "";
};

const numericParser = (value: number | string): number => {
  return pipe(
    value,
    (value) => (typeof value === "string" ? parseInt(value, 10) : value),
    (value) => (!value || isNaN(value) ? 0 : value),
    (value) => Math.max(value, 0),
  );
};

const getCellCredibility = (fieldName: keyof PersoForm, row: Row) => {
  if (isSuspicious(fieldName, row)) {
    return "not-credible-cell";
  }
  return "credible-cell";
};

const isSuspicious = (fieldName: keyof PersoForm, row: Row) => {
  const question = formValues.find((q: Question) => q.name === fieldName);

  if (!question || !question.suspicionConditions) {
    return false;
  }

  return question.suspicionConditions.some((suspicionCondition: Condition) => {
    if (isConditionAND(suspicionCondition)) {
      return isSuspiciousConditionAND(row, suspicionCondition);
    }

    return isAnswerSuspicious(row, suspicionCondition);
  });
};

const isSuspiciousConditionAND = (row: Row, condition: ConditionAND) => {
  if (!condition.AND.length) {
    return false;
  }

  return condition.AND.every((suspicionCondition: ConditionSimple) =>
    isAnswerSuspicious(row, suspicionCondition),
  );
};

const isAnswerSuspicious = (
  row: Row,
  suspicionCondition: ConditionSimple,
): boolean => {
  return compare(
    row[suspicionCondition.question as keyof PersoForm],
    suspicionCondition,
  );
};

const isConditionAND = (condition: Condition): condition is ConditionAND => {
  return "AND" in condition;
};
